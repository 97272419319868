$(() => {
	$(() => {
		initMap();
	});

	function initMap(){
		let centerMap = {};
		if(window.innerWidth >= 1366)
			centerMap = {lat: 50.45846, lng: 30.4764};
		else if(window.innerWidth >= 1024)
			centerMap = {lat: 50.45736, lng: 30.4802};
		else
			centerMap = {lat: 50.45758, lng: 30.4914};

		const element = $("#map")[0],
			markerUrl = "../img/marker.svg",
			widthMarker = (window.innerWidth >= 768) ? 50 : 30,
			heightMarker = (window.innerWidth >= 768) ? 71 : 44,
			map = new google.maps.Map(element, {
				center: centerMap,
				zoom: 15,
				disableDefaultUI: true,
				styles: [
				    {
				        "featureType": "administrative",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "visibility": "on"
				            },
				            {
				                "color": "#dedede"
				            }
				        ]
				    },
				    {
				        "featureType": "administrative",
				        "elementType": "labels.text.stroke",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "administrative.country",
				        "elementType": "geometry.fill",
				        "stylers": [
				            {
				                "color": "#fcfcfc"
				            }
				        ]
				    },
				    {
				        "featureType": "landscape",
				        "elementType": "geometry.fill",
				        "stylers": [
				            {
				                "color": "#fcfcfc"
				            },
				            {
				                "visibility": "on"
				            }
				        ]
				    },
				    {
				        "featureType": "landscape",
				        "elementType": "labels",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "landscape.man_made",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "visibility": "on"
				            },
				            {
				                "color": "#fcfcfc"
				            }
				        ]
				    },
				    {
				        "featureType": "landscape.man_made",
				        "elementType": "geometry.fill",
				        "stylers": [
				            {
				                "color": "#fcfcfc"
				            }
				        ]
				    },
				    {
				        "featureType": "poi",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "color": "#fcfcfc"
				            }
				        ]
				    },
				    {
				        "featureType": "poi",
				        "elementType": "labels",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "poi.attraction",
				        "elementType": "geometry.fill",
				        "stylers": [
				            {
				                "color": "#fcfcfc"
				            },
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "poi.school",
				        "elementType": "geometry.fill",
				        "stylers": [
				            {
				                "color": "#f2f2f2"
				            }
				        ]
				    },
				    {
				        "featureType": "road",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "lightness": 57
				            },
				            {
				                "color": "#f5f5f5"
				            }
				        ]
				    },
				    {
				        "featureType": "road",
				        "elementType": "labels.text.fill",
				        "stylers": [
				            {
				                "visibility": "on"
				            },
				            {
				                "lightness": 24
				            },
				            {
				                "color": "#acacac"
				            }
				        ]
				    },
				    {
				        "featureType": "road",
				        "elementType": "labels.text.stroke",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "road",
				        "elementType": "labels.icon",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "road.arterial",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "color": "#f5f5f5"
				            }
				        ]
				    },
				    {
				        "featureType": "road.arterial",
				        "elementType": "labels.text",
				        "stylers": [
				            {
				                "visibility": "on"
				            },
				            {
				                "color": "#acacac"
				            }
				        ]
				    },
				    {
				        "featureType": "road.arterial",
				        "elementType": "labels.text.stroke",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "transit",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "color": "#f5f5f5"
				            }
				        ]
				    },
				    {
				        "featureType": "transit",
				        "elementType": "labels",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "transit",
				        "elementType": "labels.text.fill",
				        "stylers": [
				            {
				                "color": "#c9c9c9"
				            }
				        ]
				    },
				    {
				        "featureType": "transit",
				        "elementType": "labels.text.stroke",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "transit.station",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "color": "#f5f5f5"
				            }
				        ]
				    },
				    {
				        "featureType": "water",
				        "elementType": "geometry.fill",
				        "stylers": [
				            {
				                "color": "#9ab7ce"
				            }
				        ]
				    },
				    {
				        "featureType": "water",
				        "elementType": "labels",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    }
				]
			}),
			marker = new google.maps.Marker({
				position: {lat: 50.45740, lng: 30.4915},
				map: map,
				icon: {
					url: markerUrl,
					scaledSize: new google.maps.Size(widthMarker, heightMarker)
				}
			});
	}

	$(window).resize(() => {
		initMap();
	});
});

$(() => {
	$(() => {
		openModalWriteUs();
		openCalendar();
		openBurgerMenu();
		setFixedHeader();
		setStyleScroll();
		galleryLightBox();
		smoothScroll();
	});

	function setStyleScroll(){
		let scrollBar = null;

		if((navigator.platform.indexOf("iPhone") == -1) || (navigator.platform.indexOf("iPod") == -1)) {
			_setVisibleScrollbar(scrollBar);

			$(window).resize(() => {
				_setVisibleScrollbar(scrollBar);
			});
		}
	}

	function _setVisibleScrollbar(scrollBar){
		if(!scrollBar && window.innerWidth < 580) {
			scrollBar = new PerfectScrollbar('.wait-for-you__wrap');
		} else if(scrollBar && window.innerWidth < 580) {
			scrollBar.update();
		} else if(scrollBar && window.innerWidth >= 580) {
			scrollBar.destroy();
			scrollBar = null;
		}
	}

	function galleryLightBox(){
		$('.gallery').magnificPopup({
			delegate: 'a',
			type: 'image',
			gallery: {
				enabled: true
			},
			zoom: {
				enabled: true,
				duration: 400
			}
		});
	}

	function openModalWriteUs(){
		const btnModalOpen = $(".modal-open"),
			classBg = $(".background"),
			classModal = $(".modal--write-us"),
			btnModalClose = $(".modal__close"),
			classModalOpen = 'modal--open-top',
			classBgOpen = 'background--open',
			classInputCheckbox = ".input--checkbox",
			classBtn = '.btn';

		btnModalOpen.click(e => {
			e.preventDefault();

			classModal.addClass(classModalOpen);
			classBg.addClass(classBgOpen);
		});

		btnModalClose.click(() => {
			classModal.removeClass(classModalOpen);
			classBg.removeClass(classBgOpen);

			const form = btnModalClose.siblings(".form");

			$(form).trigger("reset");
			$(form).find(classInputCheckbox).prop("checked", false);
			$(form).find(classBtn).prop("disabled", 'disabled');
		});
	}

	function openCalendar(){
		$('#calendar').click(() => {
			$(".calendar__wrap").slideToggle({
				duration: '500'
			});
		});
	}

	function openBurgerMenu(){
		const classBurger = $(".burger"),
			navBurgerId = $("#navBurger"),
			navBurgerOpen = 'nav--burger-open',
			classBurgerActive = 'burger--active',
			navBurgerLink = $('.nav__link--burger');

		classBurger.click(e => {
			e.preventDefault();

			classBurger.toggleClass(classBurgerActive);

			if(navBurgerId.hasClass(navBurgerOpen))
				navBurgerId.removeClass(navBurgerOpen);
			else
				navBurgerId.addClass(navBurgerOpen);
		});

		navBurgerLink.click(() => {
			navBurgerId.removeClass(navBurgerOpen);
			classBurger.removeClass(classBurgerActive);
		});
	}

	function setFixedHeader(){
		_headerShow(window); //All browser except IE
		_headerShow('.wrapper'); //For IE11

		$(window).scroll(() => {
			_headerShow(window);
		});

		$('.wrapper').scroll(() => {
			_headerShow('.wrapper');
		});
	}

	function _headerShow(wrap){
		const navHeader = 'header__nav--header',
			classNavFixed = $(".header__nav--fixed");

		if($(wrap).scrollTop() > 100) {
			$(`.${navHeader}`).addClass(`${navHeader}-fix`);
			classNavFixed.show();
		} else {
			$(`.${navHeader}`).removeClass(`${navHeader}-fix`);
			classNavFixed.hide();
		}
	}

	function smoothScroll(){
		const page = $('html, body'),
			navLink = '.nav__link',
			btnRegister = '.move-to-registr';

		$(`${navLink}, ${btnRegister}`).click(e => {
			const { target: linkName } = e,
				anchor = $(linkName).attr('href');

			if(anchor == '#') return false;

			page.animate({
				scrollTop: $(anchor).offset().top
			}, 1000);

			return false;
		});
	}
});

class Slider {
	// static defaultArgs = {
	// 	sliderUniver: '#slider-univer',
	// 	sliderGallery: '#slider-gallery',
	// 	countSlides: ".slider__count--all",
	// 	sliderUniverPrefix: 'university',
	// 	sliderUniverGallery: 'gallery'
	// }

	constructor(){
		this.defaultArgs = {
			sliderUniver: '#slider-univer',
			sliderGallery: '#slider-gallery',
			countSlides: ".slider__count--all",
			sliderUniverPrefix: 'university',
			sliderUniverGallery: 'gallery'
		};
		this.init();
	}

	init(){
		const { sliderUniver, sliderGallery, sliderUniverPrefix, sliderUniverGallery } = this.defaultArgs;

		this.sliderInit(sliderUniver, sliderUniverPrefix);
		this.sliderInit(sliderGallery, sliderUniverGallery);

		$(window).resize(() => {
			this._sliderGalleryView('#slider-gallery');
		});
	}

	sliderInit(sliderName, controlModificator){
		const slidesCount = $(sliderName).children().length,
			{ sliderUniver, sliderGallery, countSlides } = this.defaultArgs;

		$(sliderName).next().find(countSlides).text(slidesCount);

		switch(sliderName){
			case sliderUniver:
				$(sliderName).find(countSlides).text(slidesCount);

				$(sliderName).slick({
					arrows: false,
					lazyLoad: 'ondemand'
				});
				this._sliderControl(sliderName, controlModificator);
				break;
			case sliderGallery:
				this._sliderGalleryView(sliderName);
				this._setAnimateSlides(sliderName);
				this._sliderControl(sliderName, controlModificator);
				break;
		}
	}

	_sliderGalleryView(slider){
		if(!$(slider).hasClass('slick-initialized') && window.innerWidth < 768) {
			$(slider).slick({
				arrows: false
			});
			$(slider).on('swipe', function (event, slick, direction) {
				console.log(direction);
			});
		}
		else if($(slider).hasClass('slick-initialized') && window.innerWidth > 768)
			$(slider).slick('unslick');
	}

	_sliderControl(sliderClass, controlClass){
		$(`.slider__control--${controlClass}`).click(e => {
			const { target: btn } = e,
				next = btn.closest(".slider__arrow--next"),
				prev = btn.closest(".slider__arrow--prev");

			if(!next && !prev) return false;

			if(next)
				$(sliderClass).slick('slickNext');
			else
				$(sliderClass).slick('slickPrev');

			this._getIndexActiveSlide(sliderClass, controlClass);
		});
	}

	_setAnimateSlides(sliderClass){
		$(sliderClass).on('beforeChange', (e, slider, current) => {
			$(`${sliderClass} .slick-slide[data-slick-index=${current}]`).css("opacity", "0");
			$(`${sliderClass} .slick-slide:not([data-slick-index=${current}])`).css("opacity", "1");
		});
	}

	_getIndexActiveSlide(sliderClass, textActiveClass){
		const indexActiveSlide = $(sliderClass).find('.slick-active').data("slick-index");

		$(`.slider__count--active-${textActiveClass}`).text(indexActiveSlide+1);

		if(sliderClass)
			$(sliderClass).find('.slick-active .slider__count--active').text(indexActiveSlide+1);
	}
};

$(() => {
	new Slider();
});

class Validate {
	// static defaultArgs = {
	// 	classFormField: ".form__field",
	// 	classFieldError: 'form__field--error',
	// 	labelCheckbox: ".label--checkbox",
	// 	modalSuccess: ".modal--answer-success",
	// 	modalError: ".modal--answer-error",
	// 	classBg: ".background",
	// 	classBgOpen: 'background--open',
	// 	classModalOpenTop: 'modal--open-top',
	// 	classModalOpenCenter: 'modal--open-center',
	// 	modalWriteUs: ".modal--write-us",
	// 	classInputCheckbox: ".input--checkbox",
	// 	classFieldsWrap: ".form__fields-wrap",
	// 	classForm: '.form',
	// 	classBtn: '.btn'
	// }

	constructor(){
		this.defaultArgs = {
			classFormField: ".form__field",
			classFieldError: 'form__field--error',
			labelCheckbox: ".label--checkbox",
			modalSuccess: ".modal--answer-success",
			modalError: ".modal--answer-error",
			classBg: ".background",
			classBgOpen: 'background--open',
			classModalOpenTop: 'modal--open-top',
			classModalOpenCenter: 'modal--open-center',
			modalWriteUs: ".modal--write-us",
			classInputCheckbox: ".input--checkbox",
			classFieldsWrap: ".form__fields-wrap",
			classForm: '.form',
			classBtn: '.btn'
		};
		this.formSubmit();
		this.getAdree();
		this.checkFormFields();
	}

	_validateForm(form){
		const { classFieldError, classFormField, classFieldsWrap } = this.defaultArgs,
			fields = $(form).find(classFormField),
			statusFields = [...fields].map((item, i) => {
				if( item.value == "" && !$(item).hasClass("may-be-empty") ) {
					$(item).addClass(classFieldError);
					return false;
				} else {
					$(item).removeClass(classFieldError);
					return true;
				}
			});

		return status = $.inArray(false, statusFields);
	}

	checkFormFields(){
		const { classFormField, classFieldError } = this.defaultArgs;

		$(classFormField).keyup(e => {
			const { target: field } = e;

			if($(field).hasClass(classFieldError) && field.value != "")
				$(field).removeClass(classFieldError);
		});
	}

	getAdree(){
		const { labelCheckbox, classBtn } = this.defaultArgs;

		$(labelCheckbox).click(e => {
			const { target: label } = e,
				status = $(label).prev().is(':checked') ? true : false;

			$(label).parent().siblings(classBtn).prop("disabled", status);
		});
	}

	_statusRequest(form, modal){
		const {
			classModalOpenTop,
			classModalOpenCenter,
			classBg,
			classBgOpen,
			modalWriteUs,
			classInputCheckbox,
			classBtn
		} = this.defaultArgs;

		$(modal).addClass(classModalOpenCenter);
		$(modalWriteUs).removeClass(classModalOpenTop);
		$(classBg).addClass(classBgOpen);

		$(form).trigger("reset");
		$(form).find(classInputCheckbox).prop("checked", false);
		$(form).find(classBtn).prop("disabled", 'disabled');

		const hideModal = setTimeout(() => {
			$(modal).removeClass(classModalOpenCenter);
			$(classBg).removeClass(classBgOpen);
		}, 5000);
	}

	formSubmit(){
		const { classForm, modalSuccess, modalError } = this.defaultArgs;

		$(classForm).submit(e => {
			e.preventDefault();

			const { target: form } = e,
				status = this._validateForm(form);

			if(status == -1)
				$.ajax({
					type: "POST",
					dataType: "text",
					url: "./php/send.php",
					data: $(form).serialize(),
					success: () => {
						this._statusRequest(form, modalSuccess)
					},
					error: () => {
						this._statusRequest(form, modalError)
					}
				});

			return false;
		});
	}
};

$(() => {
	new Validate();
});

class Timer {
	// static defaultArgs = {
	// 	dayElem: '#leftDays',
	// 	hourElem: '#leftHours',
	// 	minutesElem: '#leftMins',
	// 	secondsElem: '#leftSecs',
	// 	timeLeftElem: '#timeLeft'
	// }

	constructor(){
		this.defaultArgs = {
			dayElem: '#leftDays',
			hourElem: '#leftHours',
			minutesElem: '#leftMins',
			secondsElem: '#leftSecs',
			timeLeftElem: '#timeLeft'
		};
		this.timer();
	}

	_setDateCounter(){
		const timeLeft = this._getDateLeft(),
			{ dayElem, hourElem, minutesElem, secondsElem } = this.defaultArgs;

		let days = Math.floor(timeLeft / (24 * 60 * 60 * 1000)),
			hour = Math.floor(timeLeft / (60 * 60 * 1000)) % 24,
			minutes = Math.floor(timeLeft / (60 * 1000)) % 60,
			seconds = Math.floor(timeLeft / (1000)) % 60;

		this._outputData(days, dayElem);
		this._outputData(hour, hourElem);
		this._outputData(minutes, minutesElem);
		this._outputData(seconds, secondsElem);
	}

	_outputData(elem, block){
		if(elem < 10) elem = `0${elem}`;
		$(block).text(elem);
	}

	_getDateLeft(){
		const dataNow = new Date(),
			{ timeLeftElem } = this.defaultArgs,
			dataFair = $(timeLeftElem).text(),
			dataFairParse = Date.parse(dataFair),
			timeLeft = dataFairParse - dataNow;

		return timeLeft;
	}

	timer(){
		this._setDateCounter();
		const timeLeft = this._getDateLeft(),
			{ dayElem, hourElem, minutesElem, secondsElem } = this.defaultArgs;

		let timerId;

		if(timeLeft > 0) {
			timerId = setTimeout(() => this.timer(), 1000);
		} else {
			clearInterval(timerId);

			this._outputData(0, dayElem);
			this._outputData(0, hourElem);
			this._outputData(0, minutesElem);
			this._outputData(0, secondsElem);
		}
	}
};

$(() => {
	new Timer();
});
